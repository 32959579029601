import React from 'react'
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";



import { device } from '../styles/Breakpoints.js';

// import cornerIconQuery from '../queries/cornerIconQuery'

import styled from 'styled-components';

import Header from '../components/Header';
import CornerIcons from '../components/CornerIcons';





const BookingsStyles = styled.div`
      main {
        padding-top: var(--header-height--desktop);
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        @media ${device.$medium} {
          padding-top: 128px;
        }
      }
      h1 {
          display: none;
      }
      .calendly-container {
          height: 100%;
          width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .calendly-embed {
          height: 100%;
          width: 100%;
          margin-top: -66px;
          @media ${device.$medium} {
            margin-top: 0;
        }
      }

      .calendly-inline-widget.calendly-mobile {

      }
`

export default function BookingsPage({ data }) {
    // console.log('DATA', data)

    const cornerIcondata = data.cornerIconData.nodes[0];
    const pageData = data.bookingsPageData.nodes[0];
    const siteSettings = data.siteSettings.nodes[0];
    
    

    return (
      <>
        <Helmet title="Bookings | Mimi Casting">
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
            <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>
        <BookingsStyles>
          <Header data={siteSettings} />
          <CornerIcons data={cornerIcondata} siteSettings={siteSettings}/>
            <main>
              <h1>Bookings</h1>
              <div className="calendly-container">
              <div className="calendly-embed" dangerouslySetInnerHTML={{__html: pageData.embed_code}}></div>
              </div>
            </main>
        </BookingsStyles>
      </>
    )
}

export const query = graphql`
query {
    cornerIconData: allSanityCornerIcons {
        nodes {
          corner_icon__top_left_url
          corner_icon__top_right_url
          corner_icon__bottom_left_url
          corner_icon__bottom_right_url
          corner_icon__top_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__top_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
        }
    }
    bookingsPageData: allSanityBookingsPage {
    nodes {
      embed_code
    }
  }
    siteSettings: allSanitySiteSettings {
      nodes {
        logo {
          asset {
            extension
            gatsbyImageData(width: 300, layout: CONSTRAINED)
            url
          }
        }
        site_hover_color {
          rgb {
            r
            g
            b
            a
          }
        }
        cursor_sparkle
        cursor_static
        cursor_static_color {
          hex
        }
      }
    }
}
`